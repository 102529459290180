import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import { Branches, ThankYou as ThankYouSection } from "../sections/contact-us"
import { Helmet } from "react-helmet"

const ThankYou: React.FC = () => {
  return (
    <PageWrapper>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SEO title="Thank You" />
      <ThankYouSection />
      <Branches />
    </PageWrapper>
  )
}

export default ThankYou

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
`
